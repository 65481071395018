import Layout from "../components/Layout"
import React from "react"
import {
  ImageContainer,
  Person,
  Placeholder,
  StyledImage,
  TeamGrid,
} from "../components/wer-wir-sind/styled"
import { graphql } from "gatsby"

export default function WerWirSind({ location, data }) {
  return (
    <Layout
      title="Gau Alt-Burgund | Wer wir sind"
      description="Beauftragte des Gau Alt-Burgunds"
      location={location}
    >
      <TeamGrid>
        {/* Gaufü */}
        <Person>
          <ImageContainer>
            <StyledImage fluid={data.annikaUndKathi.childImageSharp.fluid} />
            <span>Annika & Kathi</span>
          </ImageContainer>
          <h3>Gauführung</h3>
          <p>
            Annika und Kathi behalten den Überblick zu allen Veranstaltungen,
            Beauftragungen und Sonstigem im Gau. Sie kümmern sich um die Gauräte
            und die Kommunikation innerhalb des Gaus sowie zu unserem
            Landesverband.
          </p>
          <a
            href={"mailto: gaufuehrung@altburgund.de"}
            rel={"noopener noreferrer"}
          >
            ✉ gaufuehrung@altburgund.de
          </a>
        </Person>

        {/* Gaukanzler/in */}
        <Person>
          <ImageContainer>
            <Placeholder />
            <span>Gloria</span>
          </ImageContainer>
          <h3>Gaukanzlerin</h3>
          <p>
            Als Gaukanzlerin kümmert sich Gloria um die Finanzen unseres Gaus.
            Zusätzlich vertritt sie uns im Stiftungsrat unseres Landesverbands.
          </p>
          <a href={"mailto: gloria@altburgund.de"} rel={"noopener noreferrer"}>
            ✉ gloria@altburgund.de
          </a>
        </Person>

        {/* Gauälteste / r */}
        <Person>
          <ImageContainer>
            <StyledImage fluid={data.till.childImageSharp.fluid} />
            <span>Till</span>
          </ImageContainer>
          <h3>Gauältester</h3>
          <p>
            Till steht der Gauführung beratend zur Seite und ist auch bei
            Problemen innerhalb der Stämme immer ansprechbar.
          </p>
          <a href={"mailto: till@altburgund.de"} rel={"noopener noreferrer"}>
            ✉ till@altburgund.de
          </a>
        </Person>
      </TeamGrid>

      <p
        style={{
          margin: "1rem auto 2rem auto",
          padding: "0 1rem",
          maxWidth: "1024px",
        }}
      >
        Damit die Zusammenarbeit in unserem Gau funktioniert, haben wir viele
        verschiedene Beauftragungen, die sich um unsere Veranstaltungen und
        weitere anfallende Aufgaben kümmern. Dazu gehören unsere
        Stufenbeauftragungen (Wölflinge, Pfadfinder, Ranger/Rover, Ältere,
        Späher), damit an alle Altersstufen unseres Gaus gedacht wird. Für die
        Schulung unserer neuen Sippenführungen gibt es einen jährlichen
        Schulungszirkel, der von unseren Schulungs- und Technikbeauftragten
        organisiert wird. Ebenfalls haben wir einen kleinen Kreis an{' '}
        <a href={"/vertrauenspersonen"}>Vertrauenspersonen</a>, an die man sich
        bei Sorgen wenden kann. Weitere Beauftragungen sind die Musische
        Beauftragung, Webmaster, Überbündische Beauftragung,
        Nachhaltigkeitsbeauftragung, Materialbeauftragung.
      </p>
    </Layout>
  )
}

export const query = graphql`
  query {
    annikaUndKathi: file(
      relativePath: { eq: "wer-wir-sind/annika-und-kathi.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    louise: file(relativePath: { eq: "wer-wir-sind/louise.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    till: file(relativePath: { eq: "ansprechpersonen/till.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
