import styled from "styled-components"
import Img from "gatsby-image"
import breakpoints from "../../constants/breakpoints"
import dimensions from "../../constants/dimensions"
import colors from "../../constants/colors"

export const TeamGrid = styled.div`
  margin: 1rem auto 2rem auto;
  padding: 0 1rem;
  max-width: ${dimensions.maxWidth}px;

  @media (max-width: ${breakpoints.large}px) {
    margin: 1rem auto 2rem auto;
    padding: 0 rem;
  }

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
`

export const Person = styled.div`
  margin: 0 0 2rem 0;

  a {
    text-decoration: none;
    color: ${colors.blueLink};

    &:hover,
    &:focus {
      text-decoration: underline dashed ${colors.blueLink};
    }

    &:visited {
      color: ${colors.blueLink};
    }
  }
`
export const Placeholder = styled.div`
  width: 100%;
  height: 400px;
  // background-color: red;
`

export const StyledImage = styled(Img)`
  width: 100%;
  height: 400px;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    z-index: 99;
    position: relative;
    width: auto;

    margin: -5rem 0 1.5rem 0;
    padding: 1rem;

    transform: rotate(-9deg);
    background-color: ${colors.blue};
    color: white;

    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 1px;
  }
`
